import logo from './media/Logo-Denta-Support.svg';
import './App.css';

function Welcome() {
  return (
  <>
   <section className="flex background-welcome justify-center items-center h-screen">
    <div className="flex flex-col w-1/5 text-center justify-center items-center">
      <h2 className="font-bold text-5xl color-head mb-12">Welcome To</h2>
      <img src={logo} className='mb-12' alt='logo' />
      <a href='/login' className="font-bold btn-bckgrnd text-lg pt-4 pb-4 rounded-full text-white pr-24 pl-24 bg-indigo-950 mb-8">Get Started</a>
    </div>
   </section>
  </>
  );
}

export default Welcome;
