import logo from './media/Logo-Denta-Support.svg';
import backbtn from './media/back-btn.svg';
import calendar from './media/Calendar.svg';
import chat from './media/Chat.svg';
import teacher from './media/ChalkboardTeacher.svg';
import chart from './media/chart.svg';
import classroom from './media/Classroom.svg';
import settings from './media/setting.svg';
import avatar from './media/Avatar.svg';
import singout from './media/SignOut.svg';
import deleteicon from './media/Delete.svg';
import star from './media/Star.svg';
import pencil from './media/Pencil.svg';
import see from './media/See.svg'; 
import x from './media/X.svg';
import rdmavatar from './media/Avatarrandom.svg';
import editimg from './media/editimg.svg';
import filter from './media/filter-lines.svg';
import chats from './media/ChatIcon.svg';

import profileicon from './media/Avatar.svg';
import './App.css';
import * as React from "react";
import leftarrow from './media/arrowleftsimple.svg';
import arrowright from './media/arrowright.svg';
import { useState } from 'react';

function Clinici(){

  const [succes,setSucces] = useState(false);
  const [addnew,setAddNew] = useState(false);
  const [atribute,setAtribute] = useState(true);
  const [recenzi,setRecenzi] = useState(false);

  const atributeClick = () => {
      setAtribute(true);
      setRecenzi(false);
  }

  const recenziClick = () => {
      setAtribute(false);
      setRecenzi(true);
  }

  const addClinic = () => {
      setAddNew(true);
  }

  const removeClinic = () => {
      setAddNew(false);
  }

  const deleteClinic = () => {
      setSucces(true);
  }

  const delXClinic = () => {
      setSucces(false);
  }

    return(
        <>
         <section className='h-screen flex items-center justify-between'>
         { /* Modal */ }
          { succes ?  
          <section className='h-screen modal-bckgrnd absolute top-0 left-0 bottom-0 right-0 z-30 flex items-center justify-center'>
          <div className='bg-neutral-100 flex items-center justify-center flex-col text-center p-8 rounded-lg'>
              <img src={deleteicon} className='mb-6' />
              <h2 className='modal-txt text-md font-medium mb-2'>Clinica a fost adăugată cu succes</h2>
              <h6 className='text-md text-sm text-grey mb-6'>Clinica a fost adăugată cu succes și o <br></br> poți vizualiza în lista de profesori</h6>
              <div className='flex items-center justify-between w-full pl-10 pr-10'>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 mr-4 rounded-lg'>Ieși</a>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 color-background rounded-lg text-white'>Vezi</a>
              </div>
          </div>
        </section> : <></> }

        
        {/* Modal for client */}
        {addnew ? 
                 <section className='h-screen modal-bckgrnd absolute top-0 right-0 bottom-0 z-30 left-0 flex flex-col justify-center items-end'>
                 <div className='flex relative items-right z-40 w-full justify-end h-full'>
                  <div className='flex bg-white w-1/4 items-start flex-col p-10'>
                     <div className='flex items-center mb-12 justify-between w-full'>
                       <h2 className='color-head font-medium text-xl'>Adaugă o clinică nouă</h2>
                       <a href='#' onClick={removeClinic}>
                         <img src={x} />
                       </a>
                     </div>
                     <div className='flex items-center mb-16 w-full justify-center'>
                       <div className='relative'>
                          <img src={rdmavatar} />
                          <a href='#' className='absolute bottom-10 right-0'>
                            <img src={editimg} />
                          </a>
                       </div>
                     </div>
                     <div className='flex items-left w-full flex-col mb-14'>
                       <form>
                         <h2 className='mb-3 color-head text-sm font-medium'>Nume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Prenume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Email:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Data de nastere:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Select subject:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                       </form>
                     </div>
                     <div className='flex items-left w-full justify-between'>
                       <a href='#' className='p-3 rounded-lg border-2 w-full mr-4 flex items-center justify-center text-sm' onClick={removeClinic}>Ieși</a>
                       <a href='#' className='p-3 rounded-lg border-2 w-full flex items-center justify-center color-background text-white text-sm' onClick={removeClinic}>Adaugă</a>
                     </div>
                   </div>
                 </div>
               </section> : <></> } 


            <nav className='w-1/6 relative h-full background-nav overflow-hidden flex flex-col items-center border-r-2'>
                <img src={logo} className='logo-nav mb-2' />
                  <ul className='flex flex-col justify-start items-left text-left p-6 w-full'>
                  <input type='text' placeholder='Search' className='border-2 mb-10 search-bar rounded-lg pt-2 pb-2 pl-4 pr-4' />
                    <li className='mb-6'>
                        <a href='/infdashboard' className='text-md link flex items-center'>
                          <img src={chart} className='mr-2' />  Dashboard
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/dashboard' className='text-md link flex items-center'>
                        <img src={teacher} className='mr-2' /> Aprobare Client
                        </a>
                    </li>
                    {/* <li className='mb-6 bg-blue-100 rounded-lg'>
                        <a href='/clinici' className='text-md pt-2 pb-2 pl-4 rounded-lg font-semibold color-head link flex items-center'>
                        <img src={classroom} className='mr-2' />    Clinici
                        </a>
                    </li> */}
                    <li className='mb-6'>
                        <a href='/chats' className='text-md link flex items-center'>
                        <img src={chat} className='mr-2' />  Chats
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/calendar' className='text-md link flex items-center'>
                        <img src={calendar} className='mr-2' />    Calendars
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='#' className='text-md link flex items-center'>
                        <img src={settings} className='mr-2' />   Settings</a>
                    </li>
                  </ul>
                  <div className='pl-6 pr-6 pb-10 flex items-center w-full absolute bottom-0'>
                    <img src={avatar} className='mr-3' />
                    <div className='mr-8'>
                        <h2 className='text-sm mb-1'>Jane Cooper</h2>
                        <h5 className='text-xs '>janecooper@gmail.com</h5>
                    </div>
                    <a href='#'>
                       <img src={singout} /> 
                    </a>
                  </div>
             </nav>
            <div className='w-4/5 pl-8 pr-16 pt-20 pb-20 h-full relative flex flex-col overflow-hidden'>
                <div className='flex w-full items-center mb-20 justify-between'>
                    <div className='texting'>
                        <h2 className='text-4xl font-semibold color-head mb-2'>Clinici</h2>
                        <p className='text-md font-regular color-txt'>Explorează pacienți cu ușurință și verifică profilurile individuale cu un click</p>
                    </div>
                    <a href='#' className='color-background text-white font-regular pt-3 pb-3 pl-6 rounded-lg pr-6' onClick={addClinic}>+ Adauga Pacienti</a>
                </div>
          <table>
            <thead className='rounded-lg border'>
              <tr className='text-left color-background border'>
                  <th className='p-6 text-white rounded-tl-lg font-medium text-sm'>Numele clinicii</th>
                <th className='p-6 text-white font-medium text-sm'>Orașul</th>
                  <th className='p-6 text-white font-medium text-sm'>Număr de angajați</th>
                  <th className='p-6 text-white font-medium text-sm'>Recenzi</th>
                <th className='p-6 text-white rounded-tr-lg font-medium text-sm'>Acțiune</th>
              </tr>
            </thead>
            <tbody className='p-3'>
              <tr className='p-4 border'>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center'>
                    <div>
                      <img src={avatar} className='mr-2' />
                    </div>
                    <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>DentalPro</h2>
                      <h5 className='text-xs link'>ID: 321312</h5>
                    </div>
                  </div>
                </td>
                <td className='p-4 color-head font-medium text-sm'>Iași</td>
                <td className='p-4 color-head font-medium text-sm'>15</td>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div>
                </td>
                <td className='p-4 color-head flex items-center font-medium text-sm'>
                  <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
                  <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
                </td>
              </tr>
              <tr className='p-4 border'>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center'>
                    <div>
                      <img src={avatar} className='mr-2' />
                    </div>
                    <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>SmileCare</h2>
                      <h5 className='text-xs link'>ID: 3213213</h5>
                    </div>
                  </div>
                </td>
                <td className='p-4 color-head font-medium text-sm'>București</td>
                <td className='p-4 color-head font-medium text-sm'>10</td>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.2/5
        </div>
                </td>
                <td className='p-4 color-head flex items-center font-medium text-sm'>
                  <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
                  <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
                </td>
              </tr>
              <tr className='p-4 border'>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center'>
                    <div>
                      <img src={avatar} className='mr-2' />
                    </div>
                    <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>HappyTeeth</h2>
                      <h5 className='text-xs link'>ID: 312312</h5>
                    </div>
                  </div>
                </td>
                <td className='p-4 color-head font-medium text-sm'>Timișoara</td>
                <td className='p-4 color-head font-medium text-sm'>08</td>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div>
                </td>
                <td className='p-4 color-head flex items-center font-medium text-sm'>
                  <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
                  <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
                </td>
              </tr>
              <tr className='p-4 border'>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center'>
                    <div>
                      <img src={avatar} className='mr-2' />
                    </div>
                    <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>OrthoDent</h2>
                      <h5 className='text-xs link'>ID: 321312</h5>
                    </div>
                  </div>
                </td>
                <td className='p-4 color-head font-medium text-sm'>Cluj-Napoca</td>
                <td className='p-4 color-head font-medium text-sm'>10</td>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div>
                </td>
                <td className='p-4 color-head flex items-center font-medium text-sm'>
                  <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
                  <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
                </td>
              </tr>
              <tr className='p-4 border'>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center'>
                    <div>
                      <img src={avatar} className='mr-2' />
                    </div>
                    <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>PureSmile</h2>
                      <h5 className='text-xs link'>ID: 3123123</h5>
                    </div>
                  </div>
                </td>
                  <td className='p-4 color-head font-medium text-sm'>Sibiu</td>
                <td className='p-4 color-head font-medium text-sm'>12</td>
                <td className='p-4 color-head font-medium text-sm'>
                  <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.8/5
        </div>
                </td>
                <td className='p-4 color-head flex items-center font-medium text-sm'>
                  <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
                  <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div className='pagination'>
            <a href="#">&laquo;</a>
            <a href="#" class="active">1</a>
            <a href="#">2</a>
            <a href="#">&raquo;</a>
          </div>
            </div>
         </section>
        </>
    )
}

export default Clinici;