import logo from './media/Logo-Denta-Support.svg';
import backbtn from './media/back-btn.svg';
import './App.css';
import Verify from './Verify';
import EnterPassword from './EnterPassword';

function SuccesMessage() {
  return (
  <>
   <section className="flex justify-center items-center h-screen">
    <a href='#' className="absolute top-0 left-0 m-10 pointer">
      <img src={backbtn} className="back-btn" />
    </a>
    <div className="flex flex-col w-1/4 text-center justify-center items-center">
      <img src={logo} className="logo" />
      <h2 className="font-bold mb-3">Successfully Registered</h2>
      <p className="font-regular mb-4 text-sm">Congratulation! Your Account is Registered in this application</p>
        <a href='#' className="font-regular text-md text-white pl-28 pr-28 pt-2 pb-2 bg-indigo-800 shadow-lg rounded-full">Next</a>
    </div>
   </section>
  </>
  );
}

export default SuccesMessage;
