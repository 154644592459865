import logo from './media/Logo-Denta-Support.svg';
import left from './media/ArrowLeft.svg';
import backbtn from './media/back-btn.svg';
import calendar from './media/Calendar.svg';
import chat from './media/Chat.svg';
import teacher from './media/ChalkboardTeacher.svg';
import chart from './media/chart.svg';
import classroom from './media/Classroom.svg';
import settings from './media/setting.svg';
import avatar from './media/Avatar.svg';
import singout from './media/SignOut.svg';
import deleteicon from './media/Delete.svg';
import x from './media/X.svg';
import rdmavatar from './media/Avatarrandom.svg';
import editimg from './media/editimg.svg';
import avatarphoto from './media/AvatarPhotoIMG.svg';
import trash from './media/Trash.svg';
import stars from './media/Stars.svg';
import pencil from './media/PencilSimpleLine.svg';
import profileicon from './media/Avatar.svg';
import './App.css';
import * as React from "react";
import { useState } from 'react';

function ClinicDetails(){
 
    const [succes,setSucces] = useState(false);
    const [addnew,setAddNew] = useState(false);
    const [atribute,setAtribute] = useState(true);
    const [recenzi,setRecenzi] = useState(false);

    const atributeClick = () => {
        setAtribute(true);
        setRecenzi(false);
    }

    const recenziClick = () => {
        setAtribute(false);
        setRecenzi(true);
    }

    const addClinic = () => {
        setAddNew(true);
    }

    const removeClinic = () => {
        setAddNew(false);
    }

    const deleteClinic = () => {
        setSucces(true);
    }

    const delXClinic = () => {
        setSucces(false);
    }

  
    return(
        <>
         <section className='h-screen flex items-center justify-between'>
          { /* Modal */ }
          { succes ?  
          <section className='h-screen modal-bckgrnd absolute top-0 left-0 bottom-0 right-0 z-30 flex items-center justify-center'>
          <div className='bg-white flex items-center justify-center flex-col text-center p-8 rounded-lg'>
              <img src={deleteicon} className='mb-6' />
              <h2 className='modal-txt text-md font-medium mb-2'>Delete profile</h2>
              <h6 className='text-md text-sm text-grey mb-6'>Clinica a fost adăugată cu succes și o <br></br> poți vizualiza în lista de profesori</h6>
              <div className='flex items-center justify-between w-full pl-10 pr-10'>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 mr-4 rounded-lg' onClick={delXClinic}>Cancel</a>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 color-background rounded-lg text-white'>Delete</a>
              </div>
          </div>
        </section> : <></> }
        {/* Modal for client */}
        {addnew ? 
                 <section className='h-screen modal-bckgrnd absolute top-0 right-0 bottom-0 z-30 left-0 flex flex-col justify-center items-end'>
                 <div className='flex relative items-right z-40 w-full justify-end h-full'>
                  <div className='flex bg-white w-1/4 items-start flex-col p-10'>
                     <div className='flex items-center mb-12 justify-between w-full'>
                       <h2 className='color-head font-medium text-xl'>Editează profilul</h2>
                       <a href='#' onClick={removeClinic}>
                         <img src={x} />
                       </a>
                     </div>
                     <div className='flex items-center mb-16 w-full justify-center'>
                       <div className='relative'>
                          <img src={rdmavatar} />
                          <a href='#' className='absolute bottom-10 right-0'>
                            <img src={editimg} />
                          </a>
                       </div>
                     </div>
                     <div className='flex items-left w-full flex-col mb-14'>
                       <form>
                         <h2 className='mb-3 color-head text-sm font-medium'>Nume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Prenume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Email:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Data de nastere:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Select subject:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                       </form>
                     </div>
                     <div className='flex items-left w-full justify-between'>
                       <a href='#' className='p-3 rounded-lg border-2 w-full mr-4 flex items-center justify-center text-sm'>Ieși</a>
                       <a href='#' className='p-3 rounded-lg border-2 w-full flex items-center justify-center color-background text-white text-sm'>Adaugă</a>
                     </div>
                   </div>
                 </div>
               </section> : <></> }
            <nav className='w-1/6 relative h-full background-nav overflow-hidden flex flex-col items-center border-r-2'>
                <img src={logo} className='logo-nav mb-2' />
                  <ul className='flex flex-col justify-start items-left text-left p-6 w-full'>
                  <input type='text' placeholder='Search' className='border-2 mb-10 search-bar rounded-lg pt-2 pb-2 pl-4 pr-4' />
                    <li className='mb-6'>
                        <a href='/infdashboard' className='text-md link flex items-center'>
                          <img src={chart} className='mr-2' />  Dashboard
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/dashboard' className='text-md link flex items-center'>
                        <img src={teacher} className='mr-2' /> Aprobare Client
                        </a>
                    </li>
                    {/* <li className='mb-6'>
                        <a href='/clinici' className='text-md link flex items-center'>
                        <img src={classroom} className='mr-2' />    Clinici
                        </a>
                    </li> */}
                    <li className='mb-6 bg-blue-100 rounded-lg'>
                        <a href='/chats' className='text-md pt-2 pb-2 pl-4 rounded-lg font-semibold color-head link flex items-center'>
                        <img src={chat} className='mr-2' />  Chats
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/calendar' className='text-md link flex items-center'>
                        <img src={calendar} className='mr-2' />    Calendars
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='#' className='text-md link flex items-center'>
                        <img src={settings} className='mr-2' />   Settings</a>
                    </li>
                  </ul>
                  <div className='pl-6 pr-6 pb-10 flex items-center w-full absolute bottom-0'>
                    <img src={avatar} className='mr-3' />
                    <div className='mr-8'>
                        <h2 className='text-sm mb-1'>Jane Cooper</h2>
                        <h5 className='text-xs '>janecooper@gmail.com</h5>
                    </div>
                    <a href='#'>
                       <img src={singout} /> 
                    </a>
                  </div>
             </nav>
            <div className='w-4/5 pl-8 pr-16 pt-20 pb-20 h-full relative flex flex-col overflow-auto'>
                <a href='/clinici' className='color-head font-semibold text-xl flex items-center mb-20'><img src={left} className='img-back mr-4' /> Detalile Clinici</a>
                <div className='flex items-center justify-between mb-20'>
                    <div className='profile-bckgrnd w-1/5 flex flex-col items-center justify-center text-center p-10 rounded-lg shadow-sm mr-12'>
                        <img src={avatarphoto} className='mb-4' />
                        <h2 className='color-head font-medium text-lg mb-2'>Spitalul SF. Spiridon Iasi</h2>
                        <h5 className='link'>ID: 053853</h5>
                    </div>
                    <div className='bg-white relative w-4/5 p-5 rounded-lg shadow-md'>
                        <div className='grid grid-cols-2 gap-5 pt-10 pb-10 pl-4 pr-4'>
                            <div className='profile-bckgrnd p-5 rounded-lg'>
                                <h5 className='link font-regular text-md mb-2'>Numele clinicii complet:</h5>
                                <h4 className='text-xl font-medium color-head'>Spitalul SF. Spiridon Iasi</h4>
                            </div>
                            <div className='profile-bckgrnd p-5 rounded-lg'>
                                <h5 className='link font-regular  text-md mb-2'>Data de naștere:</h5>
                                <h4 className='text-xl font-medium color-head'>09.05.2002</h4>
                            </div>
                            <div className='profile-bckgrnd p-5 rounded-lg'>
                                <h5 className='link font-regular  text-md mb-2'>Orasul:</h5>
                                <h4 className='text-xl font-medium color-head'>Iasi</h4>
                            </div>
                            <div className='profile-bckgrnd p-5 rounded-lg'>
                                <h5 className='link font-regular  text-md mb-2'>Numar angajati:</h5>
                                <h4 className='text-xl font-medium color-head'>5</h4>
                            </div>
                        </div>
                        <div className='flex items-center absolute bottom-0 right-0 pr-10 pb-2'>
                            <a href='#' className='mr-5 flex items-center color-head font-medium' onClick={addClinic}>
                                <img src={pencil} className='mr-2' />
                                Editează detaliile</a>
                            <a href='#' className='color-red flex items-center font-medium' onClick={deleteClinic}>
                                <img src={trash} className='mr-2' />
                                Șterge profilul
                                </a>
                        </div>
                    </div>
                </div>
                <div className='flex items-center border-b-2 mb-16'>
                    <a href='#' className='mr-10 color-background pb-3 pt-3 pl-6 pr-6 rounded-lg text-white font-regular' onClick={atributeClick}>Atributele Clinicii</a>
                    <a href='#' className='color-head font-medium pb-3 pt-3 pl-6 pr-6' onClick={recenziClick}>Recenzi</a>
                </div>
                {atribute ?                 
                <div className='grid grid-cols-5 gap-10'>
                    <div className='details-bckgrnd text-center p-5 rounded-lg'>
                        <div className='detail-name p-2 bckgrnd-detail rounded-full mb-4 text-white font-medium'>
                            <h2>Număr angajați</h2>
                        </div>
                        <h2 className='text-xl font-medium mb-3 details-paragraph font-medium'>5</h2>
                        <h3 className='text-xl font-medium link'>Angajați</h3>
                    </div>
                    <div className='details-bckgrnd text-center p-5 rounded-lg'>
                        <div className='detail-name p-2 bckgrnd-detail rounded-full mb-4 text-white font-medium'>
                            <h2>Număr angajați</h2>
                        </div>
                        <h2 className='text-xl font-medium mb-3 details-paragraph font-medium'>5</h2>
                        <h3 className='text-xl font-medium link'>Angajați</h3>
                    </div>
                    <div className='details-bckgrnd text-center p-5 rounded-lg'>
                        <div className='detail-name p-2 bckgrnd-detail rounded-full mb-4 text-white font-medium'>
                            <h2>Număr angajați</h2>
                        </div>
                        <h2 className='text-xl font-medium mb-3 details-paragraph font-medium'>5</h2>
                        <h3 className='text-xl font-medium link'>Angajați</h3>
                    </div>
                    <div className='details-bckgrnd text-center p-5 rounded-lg'>
                        <div className='detail-name p-2 bckgrnd-detail rounded-full mb-4 text-white font-medium'>
                            <h2>Număr angajați</h2>
                        </div>
                        <h2 className='text-xl font-medium mb-3 details-paragraph font-medium'>5</h2>
                        <h3 className='text-xl font-medium link'>Angajați</h3>
                    </div>
                    <div className='details-bckgrnd text-center p-5 rounded-lg'>
                        <div className='detail-name p-2 bckgrnd-detail rounded-full mb-4 text-white font-medium'>
                            <h2>Număr angajați</h2>
                        </div>
                        <h2 className='text-xl font-medium mb-3 details-paragraph font-medium'>5</h2>
                        <h3 className='text-xl font-medium link'>Angajați</h3>
                    </div>
                </div> : <></> }
                {recenzi ?                 
                <div className='grid grid-cols-3 gap-8'>
                    <div className='bg-white p-6 rounded-lg border'>
                        <img src={stars} className='mb-4' />
                        <h2 className='font-medium text-neutral-900 mb-4'>Ronald Richards</h2>
                        <p className='text-neutral-500 font-regular'>Am avut recent o experiență foarte pozitivă la Clinica XYZ și sunt încântat să împărtășesc câteva impresii despre serviciile lor. 
                        Am fost impresionat de atmosfera prietenoasă și profesionistă pe care am întâlnit-o de la primul meu contact cu clinica până la 
                        finalizarea tratamentului.</p>
                    </div>
                    <div className='bg-white p-6 rounded-lg border'>
                        <img src={stars} className='mb-4' />
                        <h2 className='font-medium text-neutral-900 mb-4'>Ronald Richards</h2>
                        <p className='text-neutral-500 font-regular'>Am avut recent o experiență foarte pozitivă la Clinica XYZ și sunt încântat să împărtășesc câteva impresii despre serviciile lor. 
                        Am fost impresionat de atmosfera prietenoasă și profesionistă pe care am întâlnit-o de la primul meu contact cu clinica până la 
                        finalizarea tratamentului.</p>
                    </div>
                    <div className='bg-white p-6 rounded-lg border'>
                        <img src={stars} className='mb-4' />
                        <h2 className='font-medium text-neutral-900 mb-4'>Ronald Richards</h2>
                        <p className='text-neutral-500 font-regular'>Am avut recent o experiență foarte pozitivă la Clinica XYZ și sunt încântat să împărtășesc câteva impresii despre serviciile lor. 
                        Am fost impresionat de atmosfera prietenoasă și profesionistă pe care am întâlnit-o de la primul meu contact cu clinica până la 
                        finalizarea tratamentului.</p>
                    </div>
                </div> : <></> }
            </div>
         </section>
        </>
    )
}

export default ClinicDetails;