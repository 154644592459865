import backbtn from './media/back-btn.svg';
import avatar from './media/Avatar.svg';
import deleteicon from './media/Delete.svg';
import x from './media/X.svg';
import rdmavatar from './media/Avatarrandom.svg';
import editimg from './media/editimg.svg';
import filter from './media/filter-lines.svg';
import chats from './media/ChatIcon.svg';
import profileicon from './media/Avatar.svg';
import star from './media/Star.svg';
import pencil from './media/Pencil.svg';
import see from './media/See.svg';
import './App.css';
import * as React from "react";
import leftarrow from './media/arrowleftsimple.svg';
import arrowright from './media/arrowright.svg';
import { useState } from 'react';
import Sidebar from './components/common/Sidebar';
function Dashboard(){

  const [succes,setSucces] = useState(false);
  const [addnew,setAddNew] = useState(false);
  const [atribute,setAtribute] = useState(true);
  const [recenzi,setRecenzi] = useState(false);

  const atributeClick = () => {
      setAtribute(true);
      setRecenzi(false);
  }

  const recenziClick = () => {
      setAtribute(false);
      setRecenzi(true);
  }

  const addClinic = () => {
      setAddNew(true);
  }

  const removeClinic = () => {
      setAddNew(false);
  }

  const deleteClinic = () => {
      setSucces(true);
  }

  const delXClinic = () => {
      setSucces(false);
  }

    return(
        <>
         <section className='h-screen flex items-center justify-between'>
          { /* Modal */ }
          { succes ?  
          <section className='h-screen modal-bckgrnd absolute top-0 left-0 bottom-0 right-0 z-30 flex items-center justify-center'>
          <div className='bg-neutral-100 flex items-center justify-center flex-col text-center p-8 rounded-lg'>
              <img src={deleteicon} className='mb-6' />
              <h2 className='modal-txt text-md font-medium mb-2'>Clinica a fost adăugată cu succes</h2>
              <h6 className='text-md text-sm text-grey mb-6'>Clinica a fost adăugată cu succes și o <br></br> poți vizualiza în lista de profesori</h6>
              <div className='flex items-center justify-between w-full pl-10 pr-10'>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 mr-4 rounded-lg'>Ieși</a>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 color-background rounded-lg text-white'>Vezi</a>
              </div>
          </div>
        </section> : <></> }
        {/* Modal for client */}
        {addnew ? 
                 <section className='h-screen modal-bckgrnd absolute top-0 right-0 bottom-0 z-30 left-0 flex flex-col justify-center items-end'>
                <div class="modal-content">
                 <div className='flex relative items-right z-40 w-full justify-start h-full'>
                  <div className='flex bg-white items-start flex-col p-10'>
                     <div className='flex items-center mb-12 justify-between w-full'>
                       <h2 className='color-head font-medium text-xl'>Adaugă un pacient nou</h2>
                       <a href='#' onClick={removeClinic}>
                         <img src={x} />
                       </a>
                     </div>
                     <div className='flex items-center mb-16 w-full justify-center'>
                       <div className='relative'>
                          <img src={rdmavatar} />
                          <a href='#' className='absolute bottom-10 right-0'>
                            <img src={editimg} />
                          </a>
                       </div>
                     </div>
                     <div className='flex items-left w-full flex-col mb-14'>
                       <form>
                         <h2 className='mb-3 color-head text-sm font-medium'>Nume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Prenume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Email:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Data de nastere:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Select subject:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                       </form>
                     </div>
                    <div className='flex items-left w-full justify-between pb-10'>
                       <a href='#' className='p-3 rounded-lg border-2 w-full mr-4 flex items-center justify-center text-sm' onClick={removeClinic}>Ieși</a>
                       <a href='#' className='p-3 rounded-lg border-2 w-full flex items-center justify-center color-background text-white text-sm'>Adaugă</a>
                     </div>
                   </div>
                 </div>
                 </div>
               </section> : <></> }
            <Sidebar />
            <div className='w-4/5 pl-8 pr-16 pt-20 pb-20 h-full relative flex flex-col overflow-hidden'>
                <div className='flex w-full items-center mb-20 justify-between'>
                    <div className='texting'>
                        <h2 className='text-4xl font-semibold color-head mb-2'>Aprobare Client</h2>
                        <p className='text-md font-regular color-txt'>Explorează pacienți cu ușurință și verifică profilurile individuale cu un click</p>
                    </div>
                    <a href='#' className='color-background text-white font-regular pt-3 pb-3 pl-6 rounded-lg pr-6' onClick={addClinic}>+ Adauga Pacienti</a>
                </div>
<table>
  <thead className='rounded-lg border'>
    <tr className='text-left color-background border'>
      <th className='p-6 text-white rounded-tl-lg font-medium text-sm'>Numele clientului</th>
      <th className='p-6 text-white font-medium text-sm'>Orașul</th>
      <th className='p-6 text-white font-medium text-sm'>Număr de poze</th>
      <th className='p-6 text-white font-medium text-sm'>Problema principală</th>
      <th className='p-6 text-white rounded-tr-lg font-medium text-sm'>Acțiune</th>
    </tr>
  </thead>
  <tbody className='p-3'>
    <tr className='p-4 border'>
      <td className='p-4 color-head font-medium text-sm'>
        <div className='flex items-center'>
            <div>
                <img src={process.env.PUBLIC_URL + "/media/img/images.png"} width={40} height={40} className='mr-2' /> 
            </div>
            <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>Alex Johnson</h2>
               <h5 className='text-xs link'>ID: 543211</h5>
            </div>
        </div>
      </td>
                  <td className='p-4 color-head font-medium text-sm'>Iași</td>
                  <td className='p-4 color-head font-medium text-sm'>15</td>
      <td className='p-4 color-head font-medium text-sm'>
        {/* <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div> */}Sensibilitate dentară
      </td>
      <td className='p-4 color-head flex items-center font-medium text-sm'>
        <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
        <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
      </td>
    </tr>
    <tr className='p-4 border'>
      <td className='p-4 color-head font-medium text-sm'>
        <div className='flex items-center'>
            <div>
                <img src={process.env.PUBLIC_URL + "/media/img/images.png"} width={40} height={40} className='mr-2' /> 
            </div>
            <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>John Doe</h2>
               <h5 className='text-xs link'>ID: 5483125</h5>
            </div>
        </div>
      </td>
                  <td className='p-4 color-head font-medium text-sm'>București</td>
                  <td className='p-4 color-head font-medium text-sm'>10</td>
      <td className='p-4 color-head font-medium text-sm'>
        {/* <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div> */}Durere de măsea
      </td>
      <td className='p-4 color-head flex items-center font-medium text-sm'>
        <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
        <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
      </td>
    </tr>
    <tr className='p-4 border'>
      <td className='p-4 color-head font-medium text-sm'>
        <div className='flex items-center'>
            <div>
                        <img src={process.env.PUBLIC_URL + "/media/img/images.png"} width={40} height={40} className='mr-2' /> 
            </div>
            <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>Mary Smith</h2>
               <h5 className='text-xs link'>ID: 532235</h5>
            </div>
        </div>
      </td>
                  <td className='p-4 color-head font-medium text-sm'>Timișoara</td>
                  <td className='p-4 color-head font-medium text-sm'>08</td>
      <td className='p-4 color-head font-medium text-sm'>
        {/* <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div> */}Carie dentară
      </td>
      <td className='p-4 color-head flex items-center font-medium text-sm'>
        <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
        <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
      </td>
    </tr>
    <tr className='p-4 border'>
      <td className='p-4 color-head font-medium text-sm'>
        <div className='flex items-center'>
            <div>
                <img src={process.env.PUBLIC_URL + "/media/img/images.png"} width={40} height={40} className='mr-2' /> 
            </div>
            <div className='flex items-left flex-col'>
               <h2 className='text-xs font-semibold mb-1'>Dianne Russell</h2>
               <h5 className='text-xs link'>ID: 5486235</h5>
            </div>
        </div>
      </td>
      <td className='p-4 color-head font-medium text-sm'>Cluj-Napoca</td>
      <td className='p-4 color-head font-medium text-sm'>05</td>
      <td className='p-4 color-head font-medium text-sm'>
        {/* <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div> */}Îmi lipsesc dinti
      </td>
      <td className='p-4 color-head flex items-center font-medium text-sm'>
        <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
        <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
      </td>
    </tr>
    <tr className='p-4 border'>
      <td className='p-4 color-head font-medium text-sm'>
        <div className='flex items-center'>
            <div>
                <img src={process.env.PUBLIC_URL + "/media/img/images.png"} width={40} height={40} className='mr-2' /> 
            </div>
            <div className='flex items-left flex-col'>
                        <h2 className='text-xs font-semibold mb-1'>Laura Brown</h2>
               <h5 className='text-xs link'>ID: 5483135</h5>
            </div>
        </div>
      </td>
                  <td className='p-4 color-head font-medium text-sm'>Constanța</td>
                  <td className='p-4 color-head font-medium text-sm'>12</td>
      <td className='p-4 color-head font-medium text-sm'>
        {/* <div className='flex items-center w-auto p-2 w-24 border rounded-full'>
          <img src={star} className='mr-2' />4.9/5
        </div> */}Gingivită
      </td>
      <td className='p-4 color-head flex items-center font-medium text-sm'>
        <a href='#' className='pt-2 flex items-center pb-2 pl-4 pr-4 text-normal rounded-lg mr-2 background-btn' onClick={addClinic}><img src={pencil} className='mr-2' />Editeaza</a>
        <a href='/profile' className='pt-2 pb-2 pl-4 pr-4 flex items-center  text-normal rounded-lg background-btn'><img src={see} className='mr-2' />Vezi</a>
      </td>
    </tr>
  </tbody>
</table>
            <div className='pagination'>
              <a href="#">&laquo;</a>
              <a href="#" class="active">1</a>
              <a href="#">2</a>
              <a href="#">&raquo;</a>
            </div>
            </div>
         </section>
        </>
    )
}

export default Dashboard;