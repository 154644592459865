import logo from './media/Logo-Denta-Support.svg';
import backbtn from './media/back-btn.svg';
import calendar from './media/Calendar.svg';
import chat from './media/Chat.svg';
import teacher from './media/ChalkboardTeacher.svg';
import chart from './media/chart.svg';
import classroom from './media/Classroom.svg';
import settings from './media/setting.svg';
import avatar from './media/Avatar.svg';
import singout from './media/SignOut.svg';
import deleteicon from './media/Delete.svg';
import x from './media/X.svg';
import rdmavatar from './media/Avatarrandom.svg';
import editimg from './media/editimg.svg';
import filter from './media/filter-lines.svg';
import chats from './media/ChatIcon.svg';
import profileicon from './media/Avatar.svg';
import './App.css';
import * as React from "react";
import leftarrow from './media/arrowleftsimple.svg';
import arrowright from './media/arrowright.svg';
import { useState } from 'react';
function InformationDashboard(){
 
    const [succes,setSucces] = useState(false);
    const [addnew,setAddNew] = useState(false);
  
    return(
        <>
         <section className='h-screen flex items-center justify-between'>
          { /* Modal */ }
          { succes ?  
          <section className='h-screen modal-bckgrnd absolute top-0 left-0 bottom-0 right-0 z-30 flex items-center justify-center'>
          <div className='bg-neutral-100 flex items-center justify-center flex-col text-center p-8 rounded-lg'>
              <img src={deleteicon} className='mb-6' />
              <h2 className='modal-txt text-md font-medium mb-2'>Clinica a fost adăugată cu succes</h2>
              <h6 className='text-md text-sm text-grey mb-6'>Clinica a fost adăugată cu succes și o <br></br> poți vizualiza în lista de profesori</h6>
              <div className='flex items-center justify-between w-full pl-10 pr-10'>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 mr-4 rounded-lg'>Ieși</a>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 color-background rounded-lg text-white'>Vezi</a>
              </div>
          </div>
        </section> : <></> }
        {/* Modal for client */}
        {addnew ? 
                 <section className='h-screen modal-bckgrnd absolute top-0 right-0 bottom-0 z-30 left-0 flex flex-col justify-center items-end'>
                 <div className='flex relative items-right z-40 w-full justify-end h-full'>
                  <div className='flex bg-white w-1/4 items-start flex-col p-10'>
                     <div className='flex items-center mb-12 justify-between w-full'>
                       <h2 className='color-head font-medium text-xl'>Adaugă o clinică nouă</h2>
                       <a href='#'>
                         <img src={x} />
                       </a>
                     </div>
                     <div className='flex items-center mb-16 w-full justify-center'>
                       <div className='relative'>
                          <img src={rdmavatar} />
                          <a href='#' className='absolute bottom-10 right-0'>
                            <img src={editimg} />
                          </a>
                       </div>
                     </div>
                     <div className='flex items-left w-full flex-col mb-14'>
                       <form>
                         <h2 className='mb-3 color-head text-sm font-medium'>Nume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Prenume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Email:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Data de nastere:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Select subject:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                       </form>
                     </div>
                     <div className='flex items-left w-full justify-between'>
                       <a href='#' className='p-3 rounded-lg border-2 w-full mr-4 flex items-center justify-center text-sm'>Ieși</a>
                       <a href='#' className='p-3 rounded-lg border-2 w-full flex items-center justify-center color-background text-white text-sm'>Adaugă</a>
                     </div>
                   </div>
                 </div>
               </section> : <></> }
            <nav className='w-1/6 relative h-full background-nav overflow-hidden flex flex-col items-center border-r-2'>
                <img src={logo} className='logo-nav mb-2' />
                  <ul className='flex flex-col justify-start items-left text-left p-6 w-full'>
                  <input type='text' placeholder='Search' className='border-2 mb-10 search-bar rounded-lg pt-2 pb-2 pl-4 pr-4' />
                    <li className='mb-6 bg-blue-100 rounded-lg'>
                        <a href='/infdashboard' className='text-md pt-2 pb-2 pl-4 rounded-lg font-semibold color-head link flex items-center'>
                          <img src={chart} className='mr-2' />  Dashboard
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/dashboard' className='text-md link flex items-center'>
                        <img src={teacher} className='mr-2' /> Aprobare Client
                        </a>
                    </li>
                    {/* <li className='mb-6'>
                        <a href='/clinici' className='text-md link flex items-center'>
                        <img src={classroom} className='mr-2' />    Clinici
                        </a>
                    </li> */}
                    <li className='mb-6'>
                        <a href='/chats' className='text-md link flex items-center'>
                        <img src={chat} className='mr-2' />  Chats
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/calendar' className='text-md link flex items-center'>
                        <img src={calendar} className='mr-2' />    Calendars
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/infdashboard' className='text-md link flex items-center'>
                        <img src={settings} className='mr-2' />   Settings</a>
                    </li>
                  </ul>
                  <div className='pl-6 pr-6 pb-10 flex items-center w-full absolute bottom-0'>
                    <img src={avatar} className='mr-3' />
                    <div className='mr-8'>
                        <h2 className='text-sm mb-1'>Jane Cooper</h2>
                        <h5 className='text-xs '>janecooper@gmail.com</h5>
                    </div>
                    <a href='#'>
                       <img src={singout} /> 
                    </a>
                  </div>
             </nav>
            <div className='w-4/5 pl-8 pr-16 pt-20 pb-20 h-full relative flex flex-col overflow-hidden'>
                <div className='flex w-full items-center mb-16 justify-between'>
                    <div className='texting'>
                        <h2 className='text-4xl font-semibold color-head'>Dashboard</h2>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-20'>
                    <div className='grid grid-cols-3 gap-4'>
                        <div className='px-8 h-36 bg-blue-100 py-6 border-blue rounded-lg'>
                           <h5 className='mb-3 color-head font-medium'>Total Clinici:</h5>
                           <h6 className='txt-blue-500 font-medium text-2xl'>3350</h6>
                        </div>
                        <div className='px-8 h-36 bg-blue-100 py-6 border-blue rounded-lg'>
                           <h5 className='mb-3 color-head font-medium'>Total Doctori:</h5>
                           <h6 className='txt-blue-500 font-medium text-2xl'>165</h6>
                        </div>
                        <div className='px-8 h-36 bg-blue-100 py-6 border-blue rounded-lg'>
                          <h5 className='mb-3 color-head font-medium'>Total Programari:</h5>
                           <h6 className='txt-blue-500 font-medium text-2xl'>1450</h6>
                        </div>
                    </div>
                    <div className='flex flex-col w-full items-center'>
                      <div className='flex border-b mb-6 pb-2 w-full items-center justify-between'>
                        <h2 className='color-head font-semibold text-lg'>Top 5 Clienti</h2>
                        <a href='#' className='color-head font-medium text-sm'>vezi tot</a>
                      </div>
                      <div className='flex flex-col w-full items-center justify-between'>
                        <div className='flex rounded-t-lg bg-indigo-800 w-full pt-2 pb-2 pl-6 pr-6 flex justify-between items-center'>
                            <h2 className='text-white text-md font-regular'>Clients</h2>
                            <h2 className='text-white text-md font-regular'>Rating</h2>
                        </div>
                        <div className='flex flex-col rounded-b-lg bg-white border w-full pt-2 pb-2 pl-6 pr-6 flex justify-between items-center'>
                            <div className='flex w-full pt-4 pb-4 items-center justify-between'>
                              <div className='flex items-center'>
                                <img src={avatar} className='mr-2' />
                                <h4 className='color-head font-medium text-sm'>Tudor Crijmar</h4>
                              </div>
                              <h5 className='text-sm'>95%</h5>
                            </div>
                            <div className='flex w-full pt-4 pb-4 items-center justify-between'>
                              <div className='flex items-center'>
                                <img src={avatar} className='mr-2' />
                                <h4 className='color-head font-medium text-sm'>Tudor Crijmar</h4>
                              </div>
                              <h5 className='text-sm'>95%</h5>
                            </div>
                            <div className='flex w-full pt-4 pb-4 items-center justify-between'>
                              <div className='flex items-center'>
                                <img src={avatar} className='mr-2' />
                                <h4 className='color-head font-medium text-sm'>Tudor Crijmar</h4>
                              </div>
                              <h5 className='text-sm'>95%</h5>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
         </section>
        </>
    )
}

export default InformationDashboard;