import logo from './media/Logo-Denta-Support.svg';
import backbtn from './media/back-btn.svg';
import './App.css';

function EnterPassword() {
  return (
  <>
   <section className="flex justify-center items-center h-screen">
    <a href='#' className="absolute top-0 left-0 m-10 pointer">
      <img src={backbtn} className="back-btn" />
    </a>
    <div className="flex flex-col w-1/4 text-center justify-center items-center">
      <img src={logo} className="logo" />
      <h2 className="font-bold mb-5">ENTER PASSWORD</h2>
      <form>
        <input type="text" placeholder="New password" className="w-full border-black bckgrnd pb-3 pt-3 mb-10 pl-6 pr-6 rounded-full" />
        <a href='/infdashboard' className="font-regular text-sm text-white pl-20 pr-20 pt-3 pb-3 bg-indigo-800 shadow-lg rounded-full">Continue</a>
      </form>
    </div>
   </section>
  </>
  );
}

export default EnterPassword;