import React from 'react';
import logo from '../../media/Logo-Denta-Support.svg';
import chart from '../../media/chart.svg';
import teacher from '../../media/ChalkboardTeacher.svg';
import classroom from '../../media/Classroom.svg';
import settings from '../../media/setting.svg';
import avatar from '../../media/Avatar.svg';
import singout from '../../media/SignOut.svg';
import calendar from '../../media/Calendar.svg';
import chat from '../../media/Chat.svg';

const Sidebar = () => {
    return (
        <nav className='w-1/6 relative h-full background-nav overflow-hidden flex flex-col items-center border-r-2'>
            <img src={logo} className='logo-nav mb-2' />
            <ul className='flex flex-col justify-start items-left text-left p-6 w-full'>
                <input type='text' placeholder='Search' className='border-2 mb-10 search-bar rounded-lg pt-2 pb-2 pl-4 pr-4' />
                <li className='mb-6'>
                    <a href='/infdashboard' className='text-md link flex items-center'>
                        <img src={chart} className='mr-2' />  Dashboard
                    </a>
                </li>
                <li className='mb-6 bg-blue-100 rounded-lg'>
                    <a href='/dashboard' className='text-md pt-2 pb-2 pl-4 rounded-lg font-semibold color-head link flex items-center'>
                        <img src={teacher} className='mr-2' /> Aprobare Client
                    </a>
                </li>
                {/* <li className='mb-6'>
                    <a href='/clinici' className='text-md link flex items-center'>
                        <img src={classroom} className='mr-2' />    Clinici
                    </a>
                </li> */}
                <li className='mb-6'>
                    <a href='/chats' className='text-md link flex items-center'>
                        <img src={chat} className='mr-2' />  Chats
                    </a>
                </li>
                <li className='mb-6'>
                    <a href='/calendar' className='text-md link flex items-center'>
                        <img src={calendar} className='mr-2' />    Calendars
                    </a>
                </li>
                <li className='mb-6'>
                    <a href='/infdashboard' className='text-md link flex items-center'>
                        <img src={settings} className='mr-2' />   Settings</a>
                </li>
            </ul>
            <div className='pl-6 pr-6 pb-10 flex items-center w-full absolute bottom-0'>
                <img src={avatar} className='mr-3' />
                <div className='mr-8'>
                    <h2 className='text-sm mb-1'>Jane Cooper</h2>
                    <h5 className='text-xs '>janecooper@gmail.com</h5>
                </div>
                <a href='#'>
                    <img src={singout} />
                </a>
            </div>
        </nav>
    );
};

export default Sidebar;