import logo from './media/Logo-Denta-Support.svg';
import React, { useState } from 'react';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { withDismissible } from './components/hoc/index';
import backbtn from './media//back-btn.svg';
import './App.css';
import {
  Link,
  useNavigate
} from "react-router-dom";
import { useAuth } from './features/AuthContext';


function Login() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const AlertDismissible = withDismissible(Alert);
  const { signIn } = useAuth();

  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.persist();
    setShow(false);
    setMessage('');
    event.preventDefault();

    const result = await signIn(event.target.email.value, event.target.password.value);
    console.log(result)
    if (result.status === 200 && (result.data.userType === "clinic" || result.data.userType === "admin")) {
      setShow(false);
      setMessage('');
      navigate(`/dashboard`);
    } else {
      setMessage(result.data ?? result.data + ": Please try again!");
      setShow(true);
    }
  }
  return (
    <>
      <section className="flex justify-center items-center h-screen">
        <a href='/' className="absolute top-0 left-0 m-10 pointer">
          <img src={backbtn} className="back-btn" alt="Back" />
        </a>
        <div className="flex flex-col w-1/4 text-center justify-center items-center">
          <img src={logo} className="logo" alt='logo'/>
          {show && message && (
            <AlertDismissible variant='danger'>{message}</AlertDismissible>
          )}
          <Form onSubmit={handleSubmit} className='flex-col flex w-full'>
            <Form.Group className='form-custom-group'>
              <Form.Control
                className='w-full border-black bckgrnd pb-3 pt-3 mb-5 pl-6 pr-6 rounded-full'
                placeholder='Type your email'
                name='email'
                type='email'
                required
                autoFocus />
            </Form.Group>

            <Form.Group className='form-custom-group'>
              <Form.Control
                className="w-full border-black bckgrnd pb-3 pt-3 mb-5 pl-6 pr-6 rounded-full"
                placeholder="Type your password"
                name='password'
                type='password'
                required />
            </Form.Group>

            <Row className='rem-password'>
              <Col sm={4}>
                <Link to='/forgotpassword' className="underline underline-offset-2">Forgot Password?</Link>
              </Col>
            </Row>

            <Button type='submit' className='font-regular mt-3 mb-6 text-sm text-white pl-14 pr-14 pt-3 pb-3 bg-indigo-800 shadow-lg rounded-full'>
              Sign In
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
}


export default Login;