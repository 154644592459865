// src/api/axios.js
import axios from 'axios';

const API = axios.create({
    baseURL: 'https://denta.api.kerrsid.com/api', // Adjust this URL to match your server's address
    headers: {
        'Content-Type': 'application/json'
    }
});

export default API;
