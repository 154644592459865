import logo from './media//Logo-Denta-Support.svg';
import backbtn from './media//back-btn.svg';
import './App.css';
import * as React from "react";
import Verify from './Verify';
import EnterPassword from './EnterPassword';
import SuccesMessage from './SuccesMessage';
import Login from './Login';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function ForgotPassword() {
  return (
    <>
   <section className="flex justify-center items-center h-screen">
    <a href='/' className="absolute top-0 left-0 m-10 pointer">
      <img src={backbtn} className="back-btn" alt="Back" />
    </a>
    <div className="flex flex-col w-1/4 text-center justify-center items-center">
      <img src={logo} className="logo" alt="Logo" />
      <h2 className="font-bold mb-3">FORGOT PASSWORD?</h2>
      <p className="font-regular mb-8">Enter your email we will send your verification code via email</p>
      <form>
        <input type="text" placeholder="Type your name" className="w-full border-black bckgrnd pb-3 pt-3 mb-8 pl-6 pr-6 rounded-full" />
        <a href='/verifycode' className="font-regular text-sm text-white pl-20 pr-20 pt-3 pb-3 bg-indigo-800 shadow-lg rounded-full">Continue</a>
      </form>
    </div>
   </section>
    </>
  );
}

export default ForgotPassword;