import logo from './media/Logo-Denta-Support.svg';
import backbtn from './media/back-btn.svg';
import calendar from './media/Calendar.svg';
import chat from './media/Chat.svg';
import teacher from './media/ChalkboardTeacher.svg';
import chart from './media/chart.svg';
import classroom from './media/Classroom.svg';
import settings from './media/setting.svg';
import avatar from './media/Avatar.svg';
import singout from './media/SignOut.svg';
import deleteicon from './media/Delete.svg';
import x from './media/X.svg';
import rdmavatar from './media/Avatarrandom.svg';
import editimg from './media/editimg.svg';
import filter from './media/filter-lines.svg';
import chats from './media/ChatIcon.svg';
import profileicon from './media/Avatar.svg';
import './App.css';
import * as React from "react";
import leftarrow from './media/arrowleftsimple.svg';
import arrowright from './media/arrowright.svg';
import { useState } from 'react';

function Calendar(){

  const [succes,setSucces] = useState(false);
  const [addnew,setAddNew] = useState(false);
  const [calendar,setCalendar] = useState(false);

  const calendarPick = () => {
    setCalendar(!calendar);
  }

    return(
        <>
         <section className='h-screen flex items-center justify-between'>
         { /* Modal */ }
          { succes ?  
          <section className='h-screen modal-bckgrnd absolute top-0 left-0 bottom-0 right-0 z-30 flex items-center justify-center'>
          <div className='bg-neutral-100 flex items-center justify-center flex-col text-center p-8 rounded-lg'>
              <img src={deleteicon} className='mb-6' />
              <h2 className='modal-txt text-md font-medium mb-2'>Clinica a fost adăugată cu succes</h2>
              <h6 className='text-md text-sm text-grey mb-6'>Clinica a fost adăugată cu succes și o <br></br> poți vizualiza în lista de profesori</h6>
              <div className='flex items-center justify-between w-full pl-10 pr-10'>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 mr-4 rounded-lg'>Ieși</a>
                  <a href='#' className='border pr-14 pl-14 pt-2 pb-2 color-background rounded-lg text-white'>Vezi</a>
              </div>
          </div>
        </section> : <></> }

        
        {/* Modal for client */}
        {addnew ? 
                 <section className='h-screen modal-bckgrnd absolute top-0 right-0 bottom-0 z-30 left-0 flex flex-col justify-center items-end'>
                 <div className='flex relative items-right z-40 w-full justify-end h-full'>
                  <div className='flex bg-white w-1/4 items-start flex-col p-10'>
                     <div className='flex items-center mb-12 justify-between w-full'>
                       <h2 className='color-head font-medium text-xl'>Adaugă o clinică nouă</h2>
                       <a href='#'>
                         <img src={x} />
                       </a>
                     </div>
                     <div className='flex items-center mb-16 w-full justify-center'>
                       <div className='relative'>
                          <img src={rdmavatar} />
                          <a href='#' className='absolute bottom-10 right-0'>
                            <img src={editimg} />
                          </a>
                       </div>
                     </div>
                     <div className='flex items-left w-full flex-col mb-14'>
                       <form>
                         <h2 className='mb-3 color-head text-sm font-medium'>Nume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Prenume:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Email:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Data de nastere:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                         <h2 className='mb-3 color-head text-sm font-medium'>Select subject:</h2>
                         <input type='text' placeholder='Enter your first name here' className='w-full input-modal rounded-lg px-4 py-2 border-2 mb-5' />
                       </form>
                     </div>
                     <div className='flex items-left w-full justify-between'>
                       <a href='#' className='p-3 rounded-lg border-2 w-full mr-4 flex items-center justify-center text-sm'>Ieși</a>
                       <a href='#' className='p-3 rounded-lg border-2 w-full flex items-center justify-center color-background text-white text-sm'>Adaugă</a>
                     </div>
                   </div>
                 </div>
               </section> : <></> } 


            <nav className='w-1/6 relative h-full background-nav overflow-hidden flex flex-col items-center border-r-2'>
                <img src={logo} className='logo-nav mb-2' />
                  <ul className='flex flex-col justify-start items-left text-left p-6 w-full'>
                  <input type='text' placeholder='Search' className='border-2 mb-10 search-bar rounded-lg pt-2 pb-2 pl-4 pr-4' />
                    <li className='mb-6'>
                        <a href='/infdashboard' className='text-md link flex items-center'>
                          <img src={chart} className='mr-2' />  Dashboard
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='/dashboard' className='text-md link flex items-center'>
                        <img src={teacher} className='mr-2' /> Aprobare Client
                        </a>
                    </li>
                    {/* <li className='mb-6'>
                        <a href='/clinici' className='text-md link flex items-center'>
                        <img src={classroom} className='mr-2' />    Clinici
                        </a>
                    </li> */}
                    <li className='mb-6'>
                        <a href='/chats' className='text-md link flex items-center'>
                        <img src={chat} className='mr-2' />  Chats
                        </a>
                    </li>
                    <li className='mb-6 bg-blue-100 rounded-lg'>
                        <a href='/calendar' className='text-md pt-2 pb-2 pl-4 rounded-lg font-semibold color-head link flex items-center'>
                        <img src={calendar} className='mr-2' />    Calendars
                        </a>
                    </li>
                    <li className='mb-6'>
                        <a href='#' className='text-md link flex items-center'>
                        <img src={settings} className='mr-2' />   Settings</a>
                    </li>
                  </ul>
                  <div className='pl-6 pr-6 pb-10 flex items-center w-full absolute bottom-0'>
                    <img src={avatar} className='mr-3' />
                    <div className='mr-8'>
                        <h2 className='text-sm mb-1'>Jane Cooper</h2>
                        <h5 className='text-xs '>janecooper@gmail.com</h5>
                    </div>
                    <a href='#'>
                       <img src={singout} /> 
                    </a>
                  </div>
             </nav>
            <div className='w-4/5 pl-8 pr-16 pt-20 pb-20 h-full relative flex flex-col overflow-hidden'>
                <div className='flex w-full items-center mb-20 justify-between mb-8'>
                    <div className='texting'>
                        <h2 className='text-4xl font-semibold color-head mb-2'>Calendar</h2>
                        <p className='text-md font-regular color-txt'>Explorează Calendarul</p>
                    </div>
                    <a href='#' onClick={calendarPick} className='color-background text-white font-regular pt-3 pb-3 pl-6 rounded-lg pr-6'>+ Adaugă un Nou Program</a>
                </div>
                {calendar ?                 
                <div className='shadow-lg bg-white mr-12 mt-20 absolute right-0 p-5 rounded-lg w-80 z-10'>
                    <div className='flex items-center justify-between mb-6'>
                        <h2 className='text-sm color-head font-medium'>January 2024</h2>
                        <div className='flex items-center'>
                            <a href='#' className='mr-4'><img src={leftarrow} className='h-4 w-4 object-contain' /></a>
                            <a href='#'><img src={arrowright} className='h-4 w-4 object-contain' /></a>
                        </div>
                    </div>
                    <div className='grid grid-cols-7 text-center gap-3'>
                        <span className='text-xs color-head font-semibold'>MON</span>
                        <span className='text-xs color-head font-semibold'>TUE</span>
                        <span className='text-xs color-head font-semibold'>WED</span>
                        <span className='text-xs color-head font-semibold'>THU</span>
                        <span className='text-xs color-head font-semibold'>FRI</span>
                        <span className='text-xs color-head font-semibold'>SAT</span>
                        <span className='text-xs color-head font-semibold'>SUN</span>
                        <span className='text-xs text-neutral-400 font-medium'>1</span>
                        <span className='text-xs text-neutral-400 font-medium'>2</span>
                        <span className='text-xs text-neutral-400 font-medium'>3</span>
                        <span className='text-xs text-neutral-400 font-medium'>4</span>
                        <span className='text-xs text-neutral-400 font-medium'>5</span>
                        <span className='text-xs text-neutral-400 font-medium'>6</span>
                        <span className='text-xs text-neutral-400 font-medium'>7</span>
                        <span className='text-xs text-neutral-400 font-medium'>8</span>
                        <span className='text-xs text-neutral-400 font-medium'>9</span>
                        <span className='text-xs text-neutral-400 font-medium'>10</span>
                        <span className='text-xs text-neutral-400 font-medium'>11</span>
                        <span className='text-xs text-neutral-400 font-medium'>12</span>
                        <span className='text-xs text-neutral-400 font-medium'>13</span>
                        <span className='text-xs text-neutral-400 font-medium'>14</span>
                        <span className='text-xs text-neutral-400 font-medium'>15</span>
                        <span className='text-xs text-neutral-400 font-medium'>16</span>
                        <span className='text-xs text-neutral-400 font-medium'>17</span>
                        <span className='text-xs text-neutral-400 font-medium'>18</span>
                        <span className='text-xs text-neutral-400 font-medium'>19</span>
                        <span className='text-xs text-neutral-400 font-medium'>20</span>
                        <span className='text-xs text-neutral-400 font-medium'>21</span>
                        <span className='text-xs text-neutral-400 font-medium'>22</span>
                        <span className='text-xs text-neutral-400 font-medium'>23</span>
                        <span className='text-xs text-neutral-400 font-medium'>24</span>
                        <span className='text-xs text-neutral-400 font-medium'>25</span>
                        <span className='text-xs text-neutral-400 font-medium'>26</span>
                        <span className='text-xs text-neutral-400 font-medium'>27</span>
                        <span className='text-xs text-neutral-400 font-medium'>28</span>
                        <span className='text-xs text-neutral-400 font-medium'>29</span>
                        <span className='text-xs text-neutral-400 font-medium'>30</span>
                        <span className='text-xs text-neutral-400 font-medium'>31</span>
                    </div>
                </div> : <></> }
                <div className='border h-screen rounded-lg'>
                    <div className='flex items-center justify-between p-6'>
                        <div className='flex items-center'>
                            <h2 className='color-head font-medium text-lg mr-8'>Ianuarie 2024</h2>
                            <a href='#' className='color-background pt-3 pb-3 pl-5 pr-5  text-white text-sm rounded-lg'>Programate</a>
                        </div>
                        <a href='#' className='color-head font-medium flex items-center pt-3 pb-3 pl-5 pr-5 border rounded-lg'>
                        <img src={calendar} className='mr-2 color-head' />
                        Ianuarie 2024</a>
                    </div>
                    <div className='grid grid-cols-7'>
                        <div className='flex border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='color-head font-medium text-md'>Monday</h2>
                        </div>
                        <div className='flex border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='color-head font-medium text-md'>Tuesday</h2>
                        </div>
                        <div className='flex border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='color-head font-medium text-md'>Wednesday</h2>
                        </div>
                        <div className='flex border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='color-head font-medium text-md'>Thursday</h2>
                        </div>
                        <div className='flex border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='color-head font-medium text-md'>Friday</h2>
                        </div>
                        <div className='flex border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='color-head font-medium text-md'>Saturday</h2>
                        </div>
                        <div className='flex border-t p-6 items-center justify-center border-b'>
                            <h2 className='color-head font-medium text-md'>Sunday</h2>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>1</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>2</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>3</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>4</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>5</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>6</h2>
                            <div className='flex items-center bg-chats rounded-full pt-2 pb-2 pl-4 pr-4'>
                                <h2 className='text-sm color-head font-semibold'>Vacanță/Liber</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>7</h2>
                            <div className='flex items-center bg-chats rounded-full pt-2 pb-2 pl-4 pr-4'>
                                <h2 className='text-sm color-head font-semibold'>Vacanță/Liber</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>8</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>9</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>10</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>11</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>12</h2>
                            <div className='flex items-center mb-2'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                            <div className='flex items-center'>
                                <span className='h-2 w-2 color-background rounded-full mr-3'></span>
                                <h2 className='text-zinc-500 font-regular'>Denta Support</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>13</h2>
                            <div className='flex items-center bg-chats rounded-full pt-2 pb-2 pl-4 pr-4'>
                                <h2 className='text-sm color-head font-semibold'>Vacanță/Liber</h2>
                            </div>
                        </div>
                        <div className='flex flex-col border-t p-6 items-center justify-center border-b border-r'>
                            <h2 className='font-medium text-md mb-6 details-paragraph'>14</h2>
                            <div className='flex items-center bg-chats rounded-full pt-2 pb-2 pl-4 pr-4'>
                                <h2 className='text-sm color-head font-semibold'>Vacanță/Liber</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </section>
        </>
    )
}

export default Calendar;