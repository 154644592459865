import * as React from "react";
import Verify from './Verify';
import EnterPassword from './EnterPassword';
import SuccesMessage from './SuccesMessage';
import Login from './Login';
import Dashboard from './Dashboard';
import ForgotPassword from './ForgotPassword';
import Clinici from './Clinici';
import Profile from './Profile';
import InformationDashboard from './InformationDashboard';
import ClinicDetails from './ClinicDetails';
import Chats from './Chats';
import Calendar from './Calendar';
import VerifyCode from './VerifyCode';
import Welcome from './Welcome';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './features/AuthContext';
import ProtectedRoute from './features/ProtectedRoute'; // Make sure this import is correct
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route index element={<Welcome />} />
          <Route path='/login' element={<Login />} />
          <Route path='/verifycode' element={<VerifyCode />} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/succesmessage' element={<SuccesMessage />} />
          <Route path='/enterpassword' element={<EnterPassword />} />
          <Route path='/verify' element={<Verify />} />

          <Route path='/dashboard' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path='/profile' element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path='/calendar' element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
          <Route path='/chats' element={<ProtectedRoute><Chats /></ProtectedRoute>} />
          <Route path='/clinicdetails' element={<ProtectedRoute><ClinicDetails /></ProtectedRoute>} />
          <Route path='/infdashboard' element={<ProtectedRoute><InformationDashboard /></ProtectedRoute>} />
          <Route path='/clinici' element={<ProtectedRoute><Clinici /></ProtectedRoute>} />
          {/* More protected routes */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;